import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M5314 9537 c-818 -820 -1108 -1116 -1161 -1187 -127 -172 -229 -352
-336 -590 -26 -59 -80 -211 -75 -215 2 -2 591 583 1308 1300 l1305 1305 105 0
c58 0 143 -4 190 -9 47 -5 130 -12 185 -16 l100 -6 -255 255 c-140 141 -259
256 -265 256 -5 0 -501 -492 -1101 -1093z"/>
<path d="M6299 9824 c-76 -76 -139 -141 -139 -144 0 -3 89 -4 197 -2 567 9
1121 -134 1572 -407 46 -28 86 -51 88 -51 7 0 131 -88 213 -150 124 -95 239
-198 345 -310 222 -234 890 -900 893 -890 12 34 -177 410 -298 594 -151 231
-237 334 -444 541 -272 271 -517 447 -856 616 -177 88 -424 183 -565 218 -22
5 -76 19 -120 30 -165 43 -408 77 -613 87 l-133 6 -140 -138z"/>
<path d="M5095 9687 c-603 -262 -1128 -719 -1481 -1290 -178 -287 -337 -678
-404 -992 -43 -205 -66 -378 -75 -570 l-7 -140 136 -135 c75 -74 139 -136 144
-138 4 -1 6 87 4 195 -3 190 6 353 29 493 22 139 53 289 70 345 5 17 18 60 28
97 40 137 126 347 200 493 148 291 335 542 574 775 399 388 930 920 917 919
-8 0 -69 -24 -135 -52z"/>
<path d="M7300 9332 c5 -10 590 -597 1300 -1307 l1292 -1290 -6 -130 c-8 -169
-23 -359 -32 -412 -3 -24 -3 -43 2 -43 5 0 126 117 269 260 l259 261 -1069
1070 c-589 589 -1106 1099 -1150 1135 -86 70 -272 197 -345 237 -25 13 -75 40
-111 60 -78 42 -233 109 -341 148 -64 22 -76 24 -68 11z"/>
<path d="M2708 6930 c-137 -138 -248 -253 -248 -257 0 -19 2190 -2193 2261
-2245 140 -104 293 -198 434 -268 137 -68 375 -164 383 -155 3 2 -581 590
-1297 1306 l-1301 1302 0 114 c0 63 4 144 9 181 13 93 22 272 13 272 -4 -1
-118 -113 -254 -250z"/>
<path d="M9413 6800 c5 -160 -7 -451 -24 -545 -5 -33 -15 -87 -20 -120 -86
-490 -312 -980 -633 -1370 -36 -44 -308 -322 -604 -618 -295 -296 -528 -535
-517 -531 11 4 44 18 73 30 29 13 55 24 59 24 9 0 241 119 318 163 237 136
454 305 665 517 166 166 167 167 278 308 176 223 350 529 452 792 29 74 56
144 60 155 34 83 95 311 118 445 37 205 51 335 54 477 l3 133 -144 142 -143
143 5 -145z"/>
<path d="M7775 4489 l-1290 -1291 -150 5 c-82 3 -210 10 -283 17 -74 6 -138
10 -143 8 -9 -3 491 -510 508 -515 8 -3 2097 2085 2143 2142 120 146 229 302
302 435 108 194 234 490 210 490 -4 0 -588 -581 -1297 -1291z"/>
<path d="M3350 5485 c0 -19 141 -318 188 -400 32 -55 61 -107 65 -115 4 -8 20
-34 35 -57 154 -236 283 -392 484 -588 170 -165 181 -175 313 -273 61 -46 115
-86 120 -90 6 -4 51 -33 100 -64 312 -196 627 -329 980 -414 33 -8 76 -19 95
-24 35 -9 173 -31 310 -49 41 -6 139 -13 218 -17 l143 -6 140 138 c76 76 139
141 139 144 0 3 -114 5 -252 5 -295 0 -454 17 -688 71 -322 74 -687 226 -938
391 -67 44 -229 159 -248 176 -7 7 -49 41 -92 77 -44 36 -138 125 -209 199
-183 191 -890 901 -897 901 -3 0 -6 -2 -6 -5z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
